.App {
  text-align: center;
}
.cms_wrapper{
    flex:1;
}
.wrapper_main{
    flex:1;
    flex-direction: row;
}
.header_wrapper {
  display: none;
}
.body-wrapper {
    /*padding-top:70px;*/
    flex:1;
}


#usermenu-btn, #reportsmenu-btn {
    margin-top:-10px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.active #usermenu-btn,.active  #reportsmenu-btn {
    color:#fff;
}
#usermenu-btn, #reportsmenu-btn {
    padding-top:10px;
    padding-bottom:0px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.active {
    background-color:#4D47DD;
    width: 100%;
    color:#fff;

    padding: 16px;
}
.not_active{
    display: inline-block;
    padding: 16px;
}
.a{
color: rgba(0, 0, 0, 0.64);
font-size: 16px;
font-weight: 500;
line-height: 24px;
font-style: normal;


}
.ch__ori_header{
    height: 64px;
}
.line{
     border-bottom: 1px solid rgba(0, 0, 0, 0.32);
}
.ch__ori_header_left {
  float: left;
  display: inline;
}
.ch__ori_header_right{
  float: right;

}
.notf{
  color: rgba(0, 0, 0, 0.64);
   display: inline-block;
   font-size: 16px;
   font-weight: 500;
   line-height: 24px;
   font-style: normal;
}
 .dropdown{
   color: rgba(0, 0, 0, 0.64);
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
     font-style: normal;
     margin-left: 40px;
}
.pointer{
    cursor:pointer;
}
.underline {
    text-decoration:underline;
}
.users {
    position:relative;
}
.menu-dropdown-icon {
    padding-top:4px;
}
.user-dropdown {
    display:block;
    position:absolute;
    top:64px;
    left:136px;
    background:#fff;
    padding:16px;
    border:1px solid #000;
    z-index:2;
}
.user-dropdown li {
    display:block;
    margin-top:12px;
}
.hide-usermenu {
    display:none;
}
.usermenu-btn {
    margin-left:-40px;
}
.dropdown {
    margin-left:0px !important;
}
.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.menu-items{
flex-direction:column;

}

.header_wrapper_2{
    display: block;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  padding: 10px 15px;
  border: none;
  margin:16px ;

}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .header_wrapper {
      background: #fff;
      display:none;
    }
    .body-wrapper {
        /*padding-top:70px;*/
        flex:1;

    }
    .header_wrapper_2{
        display: block;
    }
    .openbtn {
      font-size: 20px;
      cursor: pointer;
      background-color: #fff;
      color: #000;
      padding: 10px 15px;
      border: none;
      margin:16px;
    }


}


/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .body-wrapper {
        /*padding-top:70px;*/
        flex:1;
        flex-direction: column;

    }
    .header_wrapper {
      background: #fff;
      display:none;
    }
    .header_wrapper_2{
        display: block;
    }

}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .body-wrapper {
        /*padding-top:70px;*/
        flex:3;
        flex-direction: column;
        margin-left: 250px;
    }
    .header_wrapper {
      background: #fff;
     display: block;
      flex:1;
      height: 100%;

     width: 250px;
       position: fixed;
       z-index: 1;
       top: 0;
       left: 0;
       overflow-x: hidden;
    }
    .header_wrapper_2{
        display: none;
    }
    .menu-items{
    flex-direction:column;

    }

}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .body-wrapper {
        /*padding-top:70px;*/
        flex:3;
        margin-left: 250px;
    }
    .header_wrapper {
      background: #fff;
     /* height:70px;
      border-bottom:2px solid #4D47DD;
      width:100%;
      position:fixed;
      z-index:20;*/
      display: block;
      flex:1;
        height: 100%;

       width: 250px;
         position: fixed;
         z-index: 1;
         top: 0;
         left: 0;
         overflow-x: hidden;
    }
    .header_wrapper_2{
        display: none;
    }
    .menu-items{
    flex-direction:column;

    }


}
