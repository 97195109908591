.landing-header {
    background:#fff;
    height:96px;
}
.sosin-logo {
    width:136px;
    margin-top:8px;
}
.landing-header {
    border-bottom:1px solid #ddd;
}
.landing-main {
    background:#fff;
    padding:12px 0px 64px 0px;
}
.registraiton-wrapper {
    padding:24px;
}
.registration-box {
    background:#fff;
    padding:36px;
}
