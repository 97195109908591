.ch__request_detail{
  background-color: #fff;
}
.ch__request_detail_header{
  height:70px;
  border-bottom:2px solid #4D47DD;
}
.ch__request_detail_left,.cross_icon{
  float: left;
}
.ch__request_detail_right{
  float: right;
  align-items: center;
}
.line{
     border-bottom: 1px solid rgba(0, 0, 0, 0.32);
}
.ch__headertxt{
  padding-left: 16px;
  float: left;
}
.ch__headerbtn{
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
   font-style: normal;
   padding: 12px;
}



ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
  width: 33.33%;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #CCCCCC;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #4D47DD;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #4D47DD;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #4D47DD;;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #4D47DD;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #4D47DD;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #4D47DD;
}

.btn{
 width: auto;
}
