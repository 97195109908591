.main {

}
.company_name {
  font-size: 32px;
  font-style:normal;
  line-height: 40px;
  color:  rgba(0, 0, 0, 0.96);
}
.ch__login_box {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    width: 450px;
  	margin: auto;
    padding: 36px;
    margin-top: 24px;
    background-color:#fff;
}

.email {
  margin-top: 48px;
}
