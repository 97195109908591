.ch__ori_content {

}
.ch__ori_create_change_btn {
  float: right;
  background-color: #4D47DD;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
 padding: 16px;
 margin-bottom: 32px;

}

.ch__ori_create_change_btn:hover,
.ch__ori_create_change_btn:focus {
  
  text-decoration: none;
  cursor: pointer;
}

.ch_ori_card_left {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  text-align: center;
}
.ch_ori_card_right{
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  text-align: center;
}
.line{
     border-bottom: 1px solid rgba(0, 0, 0, 0.32);
}
.line_2{
border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.new_line{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 16px;
  padding-bottom: 16px;
margin: 0
}
.id{
  text-align: left;
}
.ic{
text-align: right;
}
.ic_new{
  text-align: center;
}
.ch_ori_card_txt{
color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  font-style: normal;

  line-height: 24px;

}
.ch_ori_recent_txt{
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
  font-style: normal;
font-weight: 500;
  line-height: 20px;
  margin-top: 32px;
  margin-bottom: 12px;
  padding-left: 8px;
  padding-right: 8px;
}
.txt{
  color: rgba(0, 0, 0, 0.96);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.modal_header{
  color: rgba(0, 0, 0, 0.96);
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  font-weight: 500;
}

.submit{
  margin-top: 16px;
}
.cms-icon {
    width:16px;
    height:16px;
    display:inline-block;
    cursor:pointer;
}
