.upload-area {
}
.wrapper {
  background-color: #eee;
  flex: 4;
  flex-direction: row;
}
.ch__request_detail_header {
  background-color:#fff;
  margin:0px;
}
