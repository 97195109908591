.cards{
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
  margin-bottom: 32px;

}
.ch__qa_header{
  font-size: 14;
  font-style: normal;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.64);
  margin-top: 32px;
  margin-bottom: 32px;
}
.qa__txt{
  font-size: 14;
  font-style: normal;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.64);

}
.inp_txt{
    margin-right: 16px;
}
