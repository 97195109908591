.ch__ori_content {

}
.ch__ori_recent_list {
  background-color:#fff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 60px;
}
.ch__ori_create_change_btn {
  float: right;
  background-color: #4D47DD;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
 padding: 16px;
 margin-bottom: 32px;
}
.ch_ori_card_left {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  text-align: center;
}
.ch_ori_card_right{
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  text-align: center;
}
.line{
     border-bottom: 1px solid rgba(0, 0, 0, 0.32);
}
.line_2{
border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.new_line{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 16px;
  padding-bottom: 16px;
margin: 0
}
.id{
  text-align: left;
}
.ic{
text-align: right;
}
.ic_new{
  text-align: right;
}
.ch_ori_card_txt{
color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  font-style: normal;

  line-height: 24px;

}
.ch_ori_recent_txt{
  color: rgba(64, 87, 128, 0.7);
  font-size: 14px;
  font-style: normal;
    font-weight: 500;
  line-height: 20px;
  margin-top: 32px;
  margin-bottom: 12px;
  padding-left: 8px;
  padding-right: 8px;
}
.txt{
  color: rgba(0, 0, 0, 0.96);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.edit_btn{
  border: none;
  text-decoration: none;
  outline: none;
}
.edit_btn.focus, .edit_btn:focus {
    outline: 0;
    box-shadow: none!important;
}
.course-stats-block {
    background:#fff;
    padding:24px;
}
.hd{
flex:1;
text-align: left;
padding-left: 16px;
}
.timetable_fl{
    flex:1;
    margin-top: 4px;
    margin-bottom: 4px;
}
.timetable_fl_2{
    flex:2;
    flex-direction: column;
}
.timetable_section{
flex:1;
flex-direction: column;
}
.timetable_header{
    display:none;
}
.timetable_header_2 {
    display:block;
}
.timetable_section_1{
    margin-right: 32px;
flex-direction:column;
}
.mob-block{
flex:1;
border-top: 1px solid rgba(0, 0, 0, 0.12);
padding:16px;
flex-direction: column;
}


/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

    .timetable_header{
        display:none;
    }
    .timetable_header_2 {
        display:block;
    }
    .timetable_section{
flex:0;
        flex-direction: column;
    }
    .timetable_fl_2{
        flex:2;
        flex-direction: column;
    }
    .mob-block{
        flex:1;
        flex-direction: column;
        padding:16px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    .timetable_fl{
        flex:1;

    }
}


/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

    .timetable_header{
        display: none;
    }
    .timetable_header_2 {
        display:block;
    }
    .timetable_section_1{
      flex:0;
      flex-direction:column;
    }
    .timetable_section{

       flex:0;
        flex-direction: column;

    }
    .timetable_fl_2{
        flex:2;
        flex-direction: column;
    }
    .mob-block{
        flex:1;
        flex-direction: column;
        padding:16px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    .timetable_fl{
        flex:1;

    }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .timetable_header{
        display: block;
    }
    .timetable_header_2 {
        display:none;
    }
    .timetable_section{
        flex:1;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding: 32px;
        flex-direction: row;
    }
    .timetable_fl_2{
        flex:2;
        flex-direction: row;
    }
    .mob-block{
        flex:1;
        flex-direction: row;
        padding:0px;
        padding: 32px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    .timetable_fl{
        flex:1;

    }

}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

    .timetable_header{
        display: block;
    }
    .timetable_header_2 {
        display:none;
    }
    .timetable_section{
        flex:1;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding: 32px;
        flex-direction: row;
    }
    .timetable_fl_2{
        flex:2;
        flex-direction: row;
    }
    .mob-block{
        flex:1;
                flex-direction: row;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        justify-content: space-between;
    }
    .timetable_fl{
        flex:1;

    }

}
