.video-link-wrapper {
    padding:16px;
}
.video-link-row {
    margin-top: 8px;
}
.video-watch-btn {
    cursor:pointer;
    color:#4D47DD;
    font-size: 14px;
    font-family: Roboto;
    font-style:normal;
    font-weight:bolder;
    line-height: 20px;
}
.video-player {
    background:#fff;
    position:relative;
    padding:36px;
    width:100%;
    height:100%;
}
.displaytag {
    z-index:9999;
    position:absolute;
    width:60px;
    height:20px;
    font-size:14px;
    line-height:20px;
    color:rgba(232, 232, 232, 0.75);
    top:50%;
    right:50%;
}
.close-btn {
    cursor:pointer;
}
.part-1 ,.part-4{
    flex:1;
}
.part-2{
    flex:2
}
.part-3{
    flex:3;
}
.part-5{
    flex:4;
}
.desc-main{
    flex:1;
    flex-direction: column;
}
.text-title{
    color: #242424;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    font-style:normal;
    line-height: 32px;
}
.text-subtitle{
 color: rgba(36, 36, 36, 0.7);
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    line-height: 24px;
}

.text-body{
    color: #242424;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    line-height: 24px;

}
.desc-left{
margin-bottom: 64px;
}
.class_header{
    display:none;
}
.class_header_2{
    display: block;
    padding: 4px;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .part-1 ,.part-4{
        flex:1;
    }
    .part-2{
        flex:2
    }
    .part-3{
        flex:3;
    }
    .part-5{
        flex:4;
    }
    .desc-main{
        flex:1;
        flex-direction: column;
    }
    .class_header{
        display:none;
    }
    .class_header_2{
        display: block;
    }
}


/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .part-1 ,.part-4{
        flex:1;
    }
    .part-2{
        flex:2
    }
    .part-3{
        flex:3;
    }
    .part-5{
        flex:4;
    }
    .desc-main{
        flex:1;
        flex-direction: column;
    }
    .class_header{
        display:none;
    }
    .class_header_2{
        display:block;
    }

}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .part-1 ,.part-4{
        flex:1;
    }
    .part-2{
        flex:2
    }
    .part-3{
        flex:3;
    }
    .part-5{
        flex:4;
    }
    .desc-main{
        flex:1;
        flex-direction: row;
    }
    .class_header{
        display: block;
    }
    .class_header_2{
        display:none;
    }

}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

    .part-1 ,.part-4{
        flex:1;
    }
    .part-2{
        flex:2
    }
    .part-3{
        flex:3;
    }
    .part-5{
        flex:4;
    }
    .desc-main{
        flex:1;
        flex-direction: row;
    }
.class_header{
    display: block;
}
.class_header_2{
    display:none;
}

}
