input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

input[type=submit] {
  background-color:rgba(0, 0, 0, 0.12);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

input[type=submit]:hover {
  background-color:rgba(0, 0, 0, 0.12);
}

.container {
  border-radius: 5px;

  padding: 20px;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
  padding-left: 16px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
  padding-right: 16px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.info{
  padding: 100px 32px 32px 32px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 100px;
  margin-bottom: 64px;
  font-size: 14;
  font-style: normal;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.64);
}
