.cn__header{
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color:  rgba(0, 0, 0, 0.96);
}
.cn__header_qa{
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color:  rgba(0, 0, 0, 0.96);
  padding-bottom: 32px;
}
.ch__confirm{
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 32px;
    margin-top: 16px;
    margin-bottom: 100px;
}
.line_h{
border-right:  1px solid rgba(0, 0, 0, 0.12);
}
.line_n{
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 32px;
  margin-top: 32px;
  margin-left: -32px;
  margin-right: -32px;
}
.ch__confirm_title_all_txt{
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color:  rgba(0, 0, 0, 0.96);
}
.ch__confirm_value_all_txt{
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  color: rgba(0, 0, 0, 0.64);
  margin-top: 4px;
  margin-bottom: 24px;
}
.ch__state{
  color: #252091;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-style: normal;
}

.ch__state_val{
  background-color: rgba(77, 71, 221, 0.16);
  border-radius: 6px;
  color: #252091;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-style: normal;
padding: 8px;
}

.main{
  margin-top: 120px;
}
.cn_save {
position:relative;
}
.save_btn{
  position: absolute;
  float: right;
  right: 0px;
  margin-top: 24px;
}
